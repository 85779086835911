/* CSS */
import "@assets/scss/all.scss";

/* JS */
import "@assets/js/lib/limet/jquery.cookies";
import "@assets/js/lib/limet/gmap";
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'lazysizes';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons); // loads the Icon plugin


 // Init jquery cookies
 $('#cookie-consent').cookies();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}